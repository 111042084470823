*,
**,
html {
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #858585;
  --secondary-color: #ffffff;
  --highlight-color: #1864ab;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
table.mantine-Table-root.__mantine-ref-hover.mantine-Table-hover.mantine-dmh27t  td svg {
  width: 20px;
}
table.mantine-Table-root.__mantine-ref-hover.mantine-Table-hover.mantine-dmh27t img {
  width: 100%;
}
.mantine-dmh27t tbody tr td {
  font-size: 12px;
  word-break: break-word;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSuccess {
  font-size: 10px;
}
.form-group label {
  display: block;
  font-size: 13px;
  margin: 10px auto 10px;
}

button.main_btn1.text-white.mt-4 {
  width: 100%;
  margin: 15px auto;
  padding: 12px;
  background: #025364;
  color: #fff;
  border: none;
  border-radius: 4px;
}
.form-group input.form-control {
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}
form.add-category {
  box-shadow: 0 0px 8px 0 rgb(0 0 0 / 9%), 0 6px 20px 0 rgb(0 0 0 / 5%);
  padding: 12px;
  border-radius: 4px;
}
.mantine-Container-root.mantine-ca5i0y {
  box-shadow: 0 0px 8px 0 rgb(0 0 0 / 9%), 0 6px 20px 0 rgb(0 0 0 / 5%);
  padding-left: 0;
  padding-right: 0;
  border-radius: 4px;
}
.invalid-feedback {
  color: red;
  font-size: 11px;
  text-align: right;
  margin: 6px auto 0;
}
.add_image-form1{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1864ab29;
}
.add_image-form1 form.add-category{
  width: 500px;
  padding: 39px;
  border-radius: 19px;
  background-color: #fff;
}
.h2_div{
  text-align: center;
}
.add_image-form1  h2{
  text-align: center;
  margin-top: 0;
  border-bottom: 3px solid #1864ab;
  display: inline-block;
  padding-bottom: 5px;
}
.add_image-form1 button.btn.main_btn1.text-white.mt-4{
  width: 100%;
  margin: 6px auto 1px;
  padding: 16px;
  background: #025364;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 19px;
  font-weight: 600;
}
.add_image-form1 .form-group input.form-control{
  padding: 12px 16px;
  margin-bottom: 10px;
}
.add_image-form1 .form-group{
  margin-bottom: 25px;
}