.general-layout {
  display: flex;
  position: relative;
  /* background-color: #ffffff; */
}

body {
  /* background: #eaeff3; */
}

.progress-container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 40%;
  bottom: 50%;
  left: 40%;
  right: 40%;
}
.spinner >span{
  position: fixed !important;
  left: 50%;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  /* background: beige; */
  padding: 100px;
}

.div_border {
  border-radius: 10px;
  background: #f0f4f8;
  border: 1px solid;
  margin-bottom: 30px;
  text-align: center;
}
h6.confirmation-msg {
  background: #ffff;
  padding: 70px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 6%), 0 6px 20px 0 rgb(0 0 0 / 1%);
}
.currency-icon {
  position: relative;
  top: 3px;
}
a.fpass {
  margin: 10px auto;
  display: block;
  text-align: right;
}
button[type="submit"]:hover {
  background: #025364!important;
}
button[type="submit"] {
  transition: .3s all ease;
}
.form-group input {
  padding: 12px 16px;
  margin-bottom: 10px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
  line-height: 1.42857143;
}
 

 

 
 
 

 
 
.row.forget-pass {
  width: 500px;
  padding: 39px;
  box-shadow: 0 0px 8px 0 rgb(0 0 0 / 9%), 0 6px 20px 0 rgb(0 0 0 / 5%);
  border-radius: 19px;
  background-color: #fff;
}
a.forget-pass {
  display: block;
  margin: 10px auto;
  text-align: right;
  font-size: 14px;
}



 

.invalid {
  font-weight: 500;
  font-size: 20px;
  color: #ddd;
}

.currency-icon-overview {
  position: relative;
  top: 4px;
}

.edit-icon {
  color: rgba(0, 0, 0, 0.568);
}

.edit-icon :hover {
  cursor: pointer;
}

.login_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.btn-close1 img {
  width: 13px !important;
  height: 15px !important;
  filter: brightness(0) invert(1);
  object-fit: contain !important;
}

.btn-close1 {
  position: absolute;
  width: 34px;
  height: 33px;
  background: #025364;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  right: 0;
  top: -8px;
}

.login_box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9c27b005;
  padding: 26px 35px;
  border-radius: 24px;
  max-width: 534px;
  border: 1px solid #9c27b040;
}

.login_box button {
  background: #9c27b0 !important;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
}

.login_box a {
  color: #000;
  text-decoration-line: none !important;
}

#search_input {
  margin-bottom: 0 !important;
}

.pagination {
  padding: 0;
  margin: 0;
  display: flex;
  margin-bottom: 20px;
}

.pagination .page-item.active .page-link {
  background-color: #1864ab3d;
  color: #1864ab;
  border-color: #1864ab3d;
  cursor: unset;
}

form.add-category select {
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 4px;
  background: transparent;
  font-size: 14px;
  width: 100%;
}

table td img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

td {
  word-break: break-all;
}

.pagination .page-link {
  background: #1864ab;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  border: 1px solid #1864ab;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin: 0 5px;
  width: 42px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pagination .page-link:hover {
  background-color: transparent;
  color: #1864ab;
}

.pagination .page-link.active {
  background: #107FE7 !important;
}

.pagination .pagination_next .page-link,
.pagination .pagination_prev .page-link {
  width: 40px;
  height: 40px;
}

.pagination li {
  line-height: 1;
  list-style: none;
}

.pagination .pagination_next .page-link span,
.pagination .pagination_prev .page-link span {

  background-size: contain;
  background-repeat: no-repeat;
  width: 9px;
  height: 12px;
  font-size: 0;
}

.pagination .pagination_prev .page-link span {
  transform: rotate(180deg);
}

.pagination_next {
  line-height: 1;
}

.mantine-Container-root.mantine-1c977fd {
  max-width: 100%;
  margin-left: auto;
  padding-left: 16px;
  margin-top: 20px;
  margin-left: 324px;
  width: 100%;
  padding-right: 31px;
}

.mantine-Container-root.mantine-1c977fd .mantine-kgob5e,
.mantine-Grid-root.mantine-kgob5e {
  margin-top: 0;
}

.shadow_box td svg {
  width: 15px;
  margin: 0 5px;
}

.shadow_box {
  box-shadow: 0 0px 8px 0 rgb(0 0 0 / 9%), 0 6px 20px 0 rgb(0 0 0 / 5%);
  padding-left: 0;
  padding-right: 0;
  border-radius: 4px;
  max-width: 100%;
}

.mantine-Container-root.mantine-d4yc2g {
  max-width: 100%;
  margin-left: auto;
  padding-left: 16px;
  margin-top: 20px;
  margin-left: 324px;
  width: 100%;
  padding-right: 31px;
}

.select_icon {
  position: relative;
  z-index: 0;
}

.select_icon select {
  background-color: white;
  appearance: none;
  cursor: pointer;
  width: 94%;
}

.select_icon svg {
  width: 13px;
  position: absolute;
  right: 13px;
  top: 38%;
  transform: translate(0, -50%);
  pointer-events: none;
  height: auto !important;
}

.add-img .mantine-Container-root.shadow_box.mantine-1hbsmcd {
  width: 100%;
  margin-top: 2rem;
  max-width: 100%;
}

.add-img .mantine-Col-root.mantine-185tmc8 {
  flex-basis: 100%;
  max-width: 100%;
  position: relative;
}

.add-img,
.add_image-form {
  max-width: 100%;
  padding-left: 16px;
  margin-top: 20px;
  margin-left: 320px;
  width: 100%;
  padding-right: 31px;
}

.add-img button {
  position: absolute;
  right: 6px;
  top: 5px;
}

.add_image-form input,
textarea,
select {
  width: 98% !important;

}

.add_image-form .flex-fg {
  display: grid;
  grid-template-columns: auto auto;
}

.add_image-form .mantine-Col-root.mantine-1dtfwoe {
  flex-basis: 65%;
  max-width: 65%;
}

button.close-tags {
  position: absolute;
  right: 14px;
  top: 17%;
  background: #1864ab;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
}

.add_image-form .form-group.tags {
  position: relative;
}

.add_image-form button.add-tags {
  background: #1864ab;
  color: #fff;
  border: none;
  border-radius: 4px;
  margin: 6px 12px;
  display: block;
  padding: 1px 8px;
  text-align: right;
  float: right;
  font-size: 20px;
}

.add_image-form .inpt-fld-tag {
  margin: 10px auto;
  position: relative;
}

.add_image-form label {
  margin-left: 7px !important;
}

.add_image-form img {
  width: 100%;
  max-width: 100px;
  object-fit: cover;
}

.outer-container {
  padding-left: 16px;
  padding-right: 16px;
  margin-right: auto;
  margin-top: 60px;
  margin-left: 310px;
  max-width: 100%;
}

.a-dactive {
  position: relative !important;
  top: unset !important;
  right: unset !important;
}

form.add-category .form-group {
  width: 98%;
}

.add_image-form .flex-fg {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.category-frm .flex-fg {
  grid-template-columns: repeat(1, 1fr);
}

.category-frm .mantine-Col-root.mantine-1dtfwoe {
  flex-basis: 33%;
  max-width: 33%;
}

.dashboard_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
}

.dlte_btn {
  margin-bottom: 15px;
  text-align: right;
}

.check_table input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-left: 15px;
}

.dashboard_box {
  width: 24%;
  padding: 0 10px;
}

.dashboard_box:first-child {
  padding-left: 0;
}

.dashboard_inner_box {
  background-color: #1864ab;
  padding: 30px;
  border-radius: 10px;
}

.dashboard_box .btn {
  background: white;
  padding: 12px 30px;
  font-size: 18px;
  border: 0;
  cursor: pointer;
  border-radius: 7px;
  box-shadow: 0 0 25px 3px #00000021;
  font-weight: 600;
}

.dashboard_box h1 {
  color: #ffff;
  margin: 10px 0 5px;
  font-weight: 500;
  font-size: 28px;
}

.dashboard_box h3 {
  color: #ffff;
  /* margin: 10px 0; */
}

.dashboard_title {
  padding-left: 323px;
  margin-left: 0;
  max-width: 100%;
  margin-top: 40px;
}

.main_layout {
  padding-left: 337px;
  margin-left: 0;
  max-width: 100%;
  width: 100%;
  margin-top: 0;
  padding-right: 15px;
}
.inner_layout
{
  max-width: 100%;
  width: 100%;
  margin-top: 0;
  padding: '2%';

}

.sidebar1 .mob_hide {
  display: none;
}

.sidebar_overlay {
  position: fixed;
  background: #00000069;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: none;
}

.dashboard_box:nth-child(2) .dashboard_inner_box {
  background-color: #77e6fa;
}

.dashboard_box:nth-child(3) .dashboard_inner_box {
  background-color: rgb(8, 0, 0);
}

.dashboard_box:nth-child(4) .dashboard_inner_box {
  background-color: #b93e5940;
}

.table-responsive table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  margin-bottom: 22px;
  background: white;
  box-shadow: 0 0 46px 9px #0000000d;
}

.table-responsive table th:first-child {
  border-radius: 10px 0 0 0;
}

.table-responsive table th:last-child {
  border-radius: 0 10px 0 0;
}

.table-responsive table th {
  text-align: left;
  font-size: 18px;
  background: #1864ab;
  color: #fff;
  padding: 20px 15px;
  white-space: nowrap;
}

.table-responsive table tbody tr:nth-child(even) {
  background-color: #d3d3d326;
}

.table-responsive table td {
  font-size: 17px;
  border-bottom: 1px solid #d3d3d370;
  padding: 20px 15px;
  max-width: 200px;
  word-break: break-all;
}

.table-responsive table tr:last-child td {
  border-bottom: 0;
}

.sticky_header {
  position: sticky;
  top: 20px;
  z-index: 1026;
  background-color: #fff
}

.main_layout h2 {
  margin-top: 0;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #202224;
  padding: 13px 4px 13px 18px;
}

.table-responsive {
  overflow: auto;
  border-radius: 10px 10px 0 0;
}

.img_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.img_div_spacing {
  /* margin-bottom: 10px;
  border: 1px solid #d3d3d36b;
  border-radius: 10px; */
  overflow: hidden;
  display: inline-block;
  /* background: #1864ab0d; */
}

.img_div1 {
  width: 20%;
  padding: 0 11px;
  position: relative;

}

.img_div1 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.button.main_btn1.text-white.mt-4 {
  /* width: 10%;  */
  margin: 15px auto;
  padding: 12px;
  background: #025364;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.dlte_btn .add_btn {
  background: #f5535324;
  color: #f55353;
  font-weight: 600;
  font-size: 15px;
  padding: 13px 19px;
}

.card_position {
  position: relative;
}

.card_position .btn.add_btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.card_position .flex-fg {
  padding-bottom: 43px;
}
.add_btn {
  background: #4880FF;
  color: white;
  border: 0;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 15px;
}
.add_btn_add {
  background: #4880FF;
  color: white;
  border: 0;
  width: 10rem;
  padding: 3px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 15px;
}
.add_btn_delete {
  background: #ED7581;
  color: white;
  border: 0;
  width: 10rem;
  padding: 3px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 15px;
}
h2 .add_btn {
  line-height: 1.6;
}

.table-responsive td svg {
  width: 31px;
  height: 25px;
}

.add_edit_box {
  max-width: 500px;
}

.add_edit_box .add-category {
  padding: 28px;
}

.add_edit_box .add-category label {
  font-size: 17px;
}

.add_edit_box .add-category input,
.add_edit_box .add-category select {
  padding: 10px;
  margin-bottom: 17px;
}

.add_edit_box .add-category select {
  /* height: 42px; */
  width: 100% !important;
}

.add_edit_box button.btn.main_btn1.text-white.mt-4 {
  font-size: 18px;
  padding: 15px;
  font-weight: 700;
  cursor: pointer;
}

.add_edit_box.add_edit_box_image {
  max-width: 100%;
}



.add_edit_box_image .flex-fg {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.add_edit_box_image form.add-category .form-group {
  width: 48%;
}

.add_edit_box_image form.add-category .form-group.tags .close-addtag .inpt-fld-tag {
  position: relative;
}

.add_edit_box_image form.add-category .form-group.tags .close-addtag button.close-tags {
  top: 50%;
  transform: translate(0, -50%);
}

.modal_title {
  font-size: 19px !important;
  background: #1864ab;
  color: #fff;
  font-weight: 500 !important;
}

.modal_body {
  padding: 20px 24px !important;
}

.btn_cancel {
  background: #fff;
  padding: 9px 20px;
  color: #1864ab;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #1864ab;
  border-radius: 6px;
  box-shadow: none !important;
  cursor: pointer;
  outline: none !important;
}

.okay_cancel {
  background: #1864ab;
  padding: 9px 20px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid #1864ab;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: none !important;
  outline: none !important;
}

.modal_footer {
  padding-bottom: 13px !important;
}

.sidebar_bg {
  background: #FFFFFF;
  height: 100vh;
  overflow: auto;
  /* position: absolute;
left: 0;
  top:0; */
}


.sidebar_bg .mantine-1a2ct6s svg path {
  fill: #202224 !important;
}
.sidebar_bg .mantine-1a2ct6s svg g rect,
.sidebar_bg .mantine-1a2ct6s svg g circle {
  fill: #202224 !important;
}
.mantine-qxd16w path {
  fill: #202224 !important;
}
.sidebar_bg .mantine-1a2ct6s span {
  color: #202224 !important;
  font-weight: 600;
}
.sidebar_bg .mantine-gw9vz6 svg path {
  fill: #FFFFFF !important;
}
.sidebar_bg .mantine-gw9vz6 svg g rect,
.sidebar_bg .mantine-gw9vz6 svg g circle {
  fill: #FFFFFF !important;
}

.sidebar_bg .mantine-gw9vz6 span {
  color: #FFFFFF !important;
  font-weight: 600;
}

.sidebar_bg .mantine-gw9vz6 {
  background: #025364;
  box-shadow: 0 0 17px 9px #0000000d;
  border-radius: 1rem;
  text-decoration-line: none;
}

.sidebar_bg .mantine-1a2ct6s:hover {
  background: transparent;
  color: #202224;
  text-decoration-line: none;

}

.sidebar_bg .mantine-7rhvc0 {
  background-color: transparent;
  margin-bottom: 18px
}

.sidebar_bg hr {
  opacity: .2;
  margin-bottom: 17px;
}

.sidebar_bg a {
  margin-bottom: 7px;
}

.sidebar_bg .mantine-1m1n1oa {
  font-size: 22px;
  font-weight: 700;
  border: 1px solid #ffffff80;
  padding: 10px 5px;
  border-radius: 10px;
  background: #ffffff1a;
  margin-top: 10px;
  box-shadow: 0 0 17px 9px #0000000d;
}

.sidebar_bg .mantine-emyrrz {
  margin-top: 0 !important;
}

.sidebar_bg .mantine-1ayeqxv {
  margin-top: 0;
}

.open_menu {
  width: 30px;
  display: none;
}

.open_menu img {
  width: 21px;
  filter: brightness(0) invert(1);
}

.mob_title {
  display: none;
}

h3.mob_title {
  line-height: unset;
}

.mob_logo {
  font-size: 19px;
  font-weight: 700;
  border: 1px solid #1864ab;
  padding: 10px 18px;
  border-radius: 7px;
  background: #1864ab30;
  color: #1864ab;
  box-shadow: 0 0 17px 9px #0000000d;
}

.close_menu {

  position: absolute;

  left: 264px;
  top: 45px;

  z-index: 102;
  display: none;
  width: 31px;
  height: 31px;
  background: white;
  border-radius: 5px;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1039;
}

.close_menu img {
  width: 13px;
}

.close_menu {
  display: none;
}

.filter_head {
  margin-bottom: 20px;
}

.filter_head .rs-picker-toggle.rs-btn.rs-btn-default {
  border: 1px solid lightgrey;
  padding: 12px 40px 12px 16px;
}

.filter_head .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.filter_head .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 12px;
  right: 13px;
}

.filter_head {
  text-align: right;
}

.rs-picker-menu {
  right: 10px !important;
  left: unset !important;
}

.filter_head button {
  background: #1864ab;
  color: #fff;
  padding: 11px 23px;
  border-radius: 6px;
  font-size: 17px;
  font-weight: 600;
  margin-left: 10px;
}

.filter_head .rs-picker-toggle-placeholder {
  font-size: 15px;
}

.custom_check {
  margin-right: 17px;
  cursor: pointer;
}

.custom_check input {
  width: 19px;
  height: 19px;
  margin-right: 6px;
  display: inline-flex;
  align-items: center;
  position: relative;
  top: 2px;
  margin-bottom: 0 !important;
}

.user_select {
  margin-bottom: 19px;
  /* display: none; */
}

#sidebar1 {
  background: #025364;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1024;
}

.flex_row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.user_select input {
  margin: 0 !important;
  padding: 10px;
}


.add_edit_box textarea {
  border: 1px solid #ccc;
  padding: 10px;
  width: 100% !important;
}























@media (max-width:1399px) {
  .dashboard_title {
    font-size: 26px;
  }

  .dashboard_inner_box {
    padding: 13px 22px 21px;
  }

  .dashboard_box h1 {
    font-size: 23px;
  }
}

@media (max-width:1299px) {
  .sidebar_bg.mantine-ycgtpi {
    width: 280px;
    min-width: 280px;
  }

  .sidebar_bg .mantine-1m1n1oa {
    font-size: 17px;
  }

  .main_layout {
    /* padding-left: 275px */
  }

  .sidebar_bg .mantine-1a2ct6s {
    font-size: 16px;
  }
}

@media (max-width:1199px) {
  .img_div1 {
    width: 25%;
  }

  .table-responsive table td {
    min-width: 150px
  }

  .table-responsive table th {
    font-size: 17px;

  }

  .dashboard_flex {
    flex-wrap: wrap;
  }

  .dashboard_box {
    width: 48%;
    margin-bottom: 15px;
  }

  .dashboard_box {
    padding: 0 4px;
  }
}

@media (max-width:991px) {

  .sidebar1 .close_menu {
    display: inline-flex;
  }

  #sidebar1 .open_menu {
    display: inline-flex;
    z-index: 102;
    width: 45px;
    height: 45px;
    background: #1864ab;
    border-radius: 7px;
    margin-right: 15px;
    align-items: center;
    justify-content: center;
    min-width: 45px;
  }

  #sidebar1 .mob_title {
    display: inline-flex;
    align-items: center;
    width: 100%;
    position: absolute;
    padding-left: 20px;
    top: 20px;
    margin: 0;
  }

  .sidebar1 .sidebar_overlay,
  .sidebar1 .mob_hide {
    display: block;
  }

  .sidebar1 .mob_hide {
    display: block;
  }

  .main_layout {
    padding-top: 51px;
    padding-left: 17px;
  }

  .mob_hide {
    display: none;
  }

  .close_menu {
    display: inline-flex;
  }

  .menu_icon {
    display: inline-block;
  }

  .sidebar_bg.mantine-ycgtpi {
    /* width: 0;
    min-width: 0;
    display: none; */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1026;
    /* height: 100%; */
  }

  .dashboard_box {
    padding: 0;
    margin-bottom: 10px;
  }

  .dashboard_flex {
    flex-wrap: wrap;
  }

  .dashboard_box {
    width: 49%;
  }
}

@media (max-width:767px) {
  .img_div1 {
    width: 33.33%;
  }
}

@media (max-width:575px) {
  .img_div1 {
    width: 50%;
  }

  .filter_head .rs-picker-toggle-placeholder {
    font-size: 14px;

  }

  .filter_head button {
    font-size: 16px;
    padding: 11px 19px;
    margin-top: 10px;
  }

  .rs-picker-daterange-calendar-group {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .rs-picker-menu .rs-calendar {
    min-width: 100% !important;
  }

  .rs-picker-daterange-calendar-group {
    min-width: auto !important;
    height: auto !important;
  }

  .add_edit_box_image form.add-category .form-group {
    width: 100%;
  }

  .table-responsive table td {
    padding: 16px 15px;
    font-size: 16px;

    max-width: 142px;

  }

  .table-responsive table th {
    padding: 16px 15px;
  }

  .main_layout h2.subbtn {
    flex-direction: column;
    align-items: start;
  }

  .main_layout h2.subbtn .add_btn {
    margin: 10px 0px 6px;
  }

  .main_layout h2 {
    font-size: 17px;
    padding: 10px 4px 10px 18px;
    flex-wrap: wrap;
    line-height: 1.5;
  }

  .dashboard_box h1 {
    font-size: 17px;
  }

  .dashboard_inner_box {
    padding: 4px 12px 6px;
  }
}

@media only screen and (max-width: 991px) {

  .add-img,
  .add_image-form {
    margin-left: 214px;
  }

  table td img {
    width: 75px;
    height: 75px;
    object-fit: cover;
  }

  .add_image-form .mantine-Col-root.mantine-1dtfwoe {
    flex-basis: 100%;
    max-width: 100%;
  }


}





.switch1 {
  position: relative;
  display: flex;
  width: 60px;
  height: 34px;
}

.switch1 input {  
  opacity: 0;
  width: 0;
  height: 0;
}

.switch1 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch1 .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch1 input:checked + .slider {
  background-color: #2196F3;
}

.switch1 input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch1 input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switch1 .slider.round {
  border-radius: 34px;
}

.switch1 .slider.round:before {
  border-radius: 50%;
}